import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Video } from 'react-feather';

function SwiperCarousel({
  children,
  totalItems = 0,
  isShowNavigation,
  slidesPerView,
  initialSlide,
  breakpoints,
  images = [],
  ...rest
}) {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [imageThumb, setImageThumb] = useState(null);

  useEffect(() => {
    if (initialSlide) swiperRef.current.swiper.slideTo(initialSlide);
  }, [initialSlide]);

  const modules =
    images.length > 0 ? [Navigation, FreeMode, Thumbs] : [Navigation];

  return (
    <React.Fragment>
      <Swiper
        ref={swiperRef}
        navigation={true}
        modules={modules}
        className="swiper-carousel"
        slidesPerView={slidesPerView}
        thumbs={{ swiper: imageThumb }}
        breakpoints={breakpoints}
        onSlideChange={(event) => {
          setActiveIndex(event.activeIndex);
        }}
        {...rest}
      >
        {children}
        {/* Navigation */}
        {isShowNavigation && (
          <React.Fragment>
            {activeIndex !== 0 && (
              <button
                id="prevBtn"
                className="swiper-carousel__prev swiper-navigation"
                onClick={() => swiperRef.current.swiper.slidePrev()}
              >
                <ChevronLeft />
              </button>
            )}
            {activeIndex < totalItems - slidesPerView && (
              <button
                id="nextBtn"
                className="swiper-carousel__next swiper-navigation"
                onClick={() => swiperRef.current.swiper.slideNext()}
              >
                <ChevronRight />
              </button>
            )}
          </React.Fragment>
        )}
      </Swiper>

      {images.length > 0 && (
        <React.Fragment>
          <div className="view-photos-index">
            <span>{activeIndex + 1}&nbsp;</span> / {images.length}
          </div>
          <Swiper
            className="swiper-thumbs"
            onSwiper={setImageThumb}
            spaceBetween={14}
            slidesPerView={images.length}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
          >
            {_.map(images, (thumb) => (
              <SwiperSlide>
                <div className="thumb-wrap">
                  {thumb?.id === 'video' || thumb?.id === 'videos' ? (
                    <div className="video-thumb">
                      <Video />
                    </div>
                  ) : (
                    <Image
                      src={thumb.url}
                      layout="fill"
                      objectFit="cover"
                      alt={thumb.alt_text || 'photo-thumb'}
                    />
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default SwiperCarousel;
